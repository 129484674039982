@import "../utilities/swatch";
@import "productCommon";
@import "../variables";
@import "quickView";

.product-tile {
    margin-bottom: 1.5rem;

    .tile-body {
        .price {
            .sales {
                font-weight: normal;
            }

            .tiered {
                font-size: 0.875em;

                .value {
                    font-weight: normal;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .pdp-link {
            margin-bottom: .5rem;
            line-height: 1.5;
            text-transform: capitalize;

            a {
                text-decoration: none;
            }
        }

        .ratings {
            font-size: 0.9em;
        }
    }

    .image-container {
        margin-bottom: 1rem;
        position: relative;
        overflow: auto;

        .quickview {
            position: absolute;
            bottom: 14rem;
            right: 12rem;

            i {
                &.fa-expand {
                    color: rgba(0, 0, 0, 0.7);
                }

                &.fa-circle {
                    color: rgba(255, 255, 255, 0.7);
                    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                }
            }
        }

        a {
            display: block;

            .tile-image {
                width: 100%;
                height: auto;
                transition: 1s;
            }

            .tile-image--hidden {
                display: none;
            }

            .tile-image--show {
                display: block;
            }
        }
    }

    .color-swatches {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: center;
        align-items: center;
        line-height: 0;
    }

    .swatches {
        ul {
            display: flex;
            padding-left: 0;
            margin: 0;
        }

        li {
            display: inline-block;
            list-style-type: none;
        }

        .swatch--color__action {
            display: inline-block;
            position: relative;
            border-radius: 50%;
            margin-right: .5rem;
            text-decoration: none;

            // stylelint-disable-next-line
            &:focus-visible {
                outline: 1px dashed $base-color;
                border-radius: 100%;
                outline-offset: 1px;
            }

            &:hover {
                outline: 1px solid $base-color;
            }
        }

        li:not(:only-of-type) .swatch--color__action {
            &.selected {
                outline: 2px solid $base-color;
                border-radius: 100%;

                // stylelint-disable-next-line
                &:focus-visible {
                    outline: 2px dashed $base-color;
                    outline-offset: 0;
                }

                .swatch-circle {
                    border: 1px solid #FFF;
                }

                .check-holder {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    translate: -50% -50%;
                    width: 100%;
                    transform: scale(.6);
                    content: url("../images/icons/check-white.svg");
                }
            }
        }

        .swatch-overflow {
            line-height: 1.4;
            text-decoration: none;
            font-weight: bold;
        }
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(1.5rem, $white);
    }

    .product-tile_badges {
        position: absolute;
        bottom: 0;
        margin: 5px;
        right: 0;

        .product-tile_badge-item {
            margin: 2px;
            width: 3rem;
            height: 3rem;
            border-radius: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-content: center;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(sm) {
                width: 2.2rem;
                height: 2.2rem;
            }

            &.product-tile_badge-eco {
                background-color: #8BB69B;
                color: #000;
            }

            &.product-tile_badge-new {
                background-color: $grey-75;
                color: #FFF;
            }

            strong {
                font-size: 0.875rem;
                line-height: 1;
                text-align: center;

                @include media-breakpoint-down(sm) {
                    font-size: 0.75rem;
                }
            }
        }
    }

    &__promotions {
        margin-top: .5rem;
    }

    .wishlistTile {
        position: absolute;
        bottom: 14rem;
        right: 1rem;
    }
}

.einstein-carousel-slot__products {
    .carousel {
        .product-tile {
            .pdp-link {
                text-align: center;
            }

            .price {
                text-align: center;
            }
        }
    }
}

.product-tile {
    [data-bv-show=inline_rating] {
        margin-bottom: 0.5em;

        .bv_hide_visibility {
            display: none !important;
        }
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: 1px solid $grey3;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}

.product-grid .film-slide {
    /* stylelint-disable property-no-unknown */
    aspect-ratio: 119/130;
    /* stylelint-enable property-no-unknown */
}
